import { FormattedMessage, useIntl } from 'react-intl';

//import segments from 'shared/json/segments.json';
import originCountries from 'shared/json/originCountries.json';
import _categories from 'shared/json/categories.json';

const FilterItems = ({ segmentId, filter, handleSelectChange, disabled }) => {
  const { locale } = useIntl();
  const { formatMessage } = useIntl();
  //const segment = segments.find((segment) => segment.id === segmentId && segment.slugs[locale]);

  const countries = [
    // First, Spain
    originCountries.find((o) => o.country === 'es'),
    // Then, the rest
    ...originCountries
      // Remove Spain (it's already present)
      .filter((o) => o.country !== 'es')
      // Remove coutries not available in the current segment
      .filter((country) => country.segments.includes(segmentId))
      // Sort by rooms count
      .sort((a, b) => {
        if (a.rooms > b.rooms) return -1;
        if (a.rooms < b.rooms) return 1;
        return 0;
      }),
  ];

  const categories = _categories.filter((category) => category.segments.includes(segmentId)).sort((a, b) => (a.order < b.order ? -1 : 1));

  return (
    <label style={{ marginBottom: 0 }}>
      <FormattedMessage id="global-category-or-country" />
      <select name="filter" className="form-control" defaultValue={filter} onChange={handleSelectChange} disabled={disabled}>
        <option value="">{formatMessage({ id: 'global-main' })}</option>
        <hr />
        <option disabled>{formatMessage({ id: 'global-countries' })}</option>
        {countries.map((country) => (
          <option key={country.country} value={country.country}>
            {country.demonymsFemale[locale]}
          </option>
        ))}
        <hr />
        <option disabled>{formatMessage({ id: 'global-categories' })}</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.names[locale]}
          </option>
        ))}
      </select>
    </label>
  );
};

export default FilterItems;
