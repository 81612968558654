import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Card, Col, Form, Row, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSave, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { Tabs, Tab } from 'components/Tabs';
import Loading from 'components/loading';

import { getLang } from 'libs/cookies';
import { debounce } from 'libs/lodashAlt';

// Hooks
import useValidator from 'hooks/useValidator';

import DomainsList from './domainsList';
import Social from './social';
import Legal from './legal';
import Analitic from './analitic';
import Preview from 'components/whiteLabels/preview';
import CustomButton from 'components/button';
//import Favicons from './favicons';
import NewMetatagSection from 'components/whiteLabels/NewMetatagSection';
import Seo from 'components/whiteLabels/seo';

import WhiteLabelsService from 'shared/white-labels-service';
import { WHITELABELS } from 'shared/constants';

// style
import './index.scss';

const WhiteBrandForm = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const auth = useSelector((state) => state.auth.auth);
  let loading = useSelector((state) => state.loading.loading);

  const prefix_afno = '1-' + auth.id_affiliate + '-';

  const id_edit = useSelector((state) => state.whitebrand.id_edit_whitebrand);
  //const [name, setName] = useState('');
  const [name, setName] = useState(undefined);
  // Social
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [telegram, setTelegram] = useState('');
  const [social_default_image, setSocialDefaultImage] = useState(undefined);
  const [afno, setAfno] = useState('');

  const [notifications_email, setNotificationsEmail] = useState('');
  const [logo, setLogo] = useState(undefined);
  const [primary_color, setPrimaryColor] = useState('#fc531d');

  //const [domain_owner_name, setDomainOwnerName] = useState('');
  //const [domain_owner_email, setDomainOwnerEmail] = useState('');

  const [domain_owner_name, setDomainOwnerName] = useState(undefined);
  const [domain_owner_email, setDomainOwnerEmail] = useState(undefined);

  //const [use_live_thumbs, setUseLiveThumbs] = useState(true);       // TODO BORRAR

  const [domain, setDomain] = useState('');
  //const [domains, setDomains] = useState([]);

  const [favicon, setFavicon] = useState(undefined);

  const [gtm, setGTM] = useState('');
  const [ga, setGA] = useState('');
  const [mode, setMode] = useState(WHITELABELS.MODES.REGISTERED);

  const [metatagsItems, setMetatagsItems] = useState({});

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  // Functions
  const saveTwitter = (e) => setTwitter(e.target.value);
  const saveInstagram = (e) => setInstagram(e.target.value);
  const saveTelegram = (e) => setTelegram(e.target.value);
  const saveSocialDefaultImage = (e) => setSocialDefaultImage(e.target.value);
  const savePrimaryColor = (e) => setPrimaryColor(e.target.value);
  const saveDomainEmail = (e) => setDomainOwnerEmail(e.target.value);
  const saveDomainName = (e) => setDomainOwnerName(e.target.value);

  const saveImage = (event, type) => {
    // Check Size also
    if (event.target.files && event.target.files[0]) {
      if (!event.target.files[0].type.includes('webp')) {
        if (event.target.files[0].size > 2048) {
          // CHECK MAX SIZE
          switch (type) {
            case 'logo':
              setLogo(event.target.files[0]);
              break;
            case 'favicon':
              setFavicon(event.target.files[0]);
              break;
            case 'opengraph':
              setSocialDefaultImage(event.target.files[0]);
              break;
            default:
              setLogo(event.target.files[0]);
              break;
          }
        } else toast.error(<FormattedMessage id="global-file-too-big" />);
      } else toast.error(<FormattedMessage id="global-file-image-type-not-allowed" />);
    }
  };

  const debouncedOnChangeColor = debounce((name, value) => {
    savePrimaryColor({ target: { name, value } });
    //handleOnChange({target: { name, value }}, 'designForm');
    //console.log(value);
  }, 150);

  const updateBtn = () => {
    let config = {
      NAME: name,
      TWITTER: twitter,
      INSTAGRAM: instagram,
      TELEGRAM: telegram,
      AFNO: prefix_afno + afno,
      NOTIFICATIONS_EMAIL: notifications_email,
      PRIMARY_COLOR: primary_color,
      DOMAIN_OWNER_NAME: domain_owner_name,
      DOMAIN_OWNER_EMAIL: domain_owner_email,
      GTM: gtm,
      GA: ga,
    };

    if (validator.allValid()) {
      config.ID = id_edit;
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.update(config, metatagsItems)
        .then((response) => {
          if (response.status === 'Failed') {
            toast.error(<FormattedMessage id="promo-white-brand-failed-updating" />);
          } else toast.success(<FormattedMessage id="promo-white-brand-updated" />);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  const history = useHistory();
  useEffect(() => {
    if (id_edit) {
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.get({ id: id_edit })
        .then(({ message, status }) => {
          if (status === 'Accepted' && message.config.length > 0) {
            let comp_afno = message.config[0].AFNO;
            let pref_afno = ('1-' + auth.id_affiliate + '-').toString();
            let tracking_id = comp_afno.substring(pref_afno.length);

            setName(message.config[0].NAME);
            message.config[0].TWITTER && setTwitter(message.config[0].TWITTER);
            message.config[0].INSTAGRAM && setInstagram(message.config[0].INSTAGRAM);
            message.config[0].TELEGRAM && setTelegram(message.config[0].TELEGRAM);
            message.config[0].SOCIAL_DEFAULT_IMAGE && setSocialDefaultImage(message.config[0].SOCIAL_DEFAULT_IMAGE);
            setAfno(tracking_id);
            setNotificationsEmail(message.config[0].NOTIFICATIONS_EMAIL);
            setLogo(message.config[0].LOGO);
            setPrimaryColor(message.config[0].PRIMARY_COLOR);
            setDomainOwnerName(message.config[0].DOMAIN_OWNER_NAME);
            setDomainOwnerEmail(message.config[0].DOMAIN_OWNER_EMAIL);
            setGTM(message.config[0].GTM);
            setGA(message.config[0].GA);
            //setUseLiveThumbs(message.config[0].USE_LIVE_THUMBS);
            setMode(message.config[0].MODE);
            //Favicon
            if (message.favicons[0]) {
              setFavicon(message.favicons[0].HREF);
            }

            // SET DOMAINS DATA
            //response.message.domains.map((item, index) => setDomains([...domains, item.URL]));
            setDomain(message.domains[0].URL);

            setMetatagsItems(message.metatags);
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else {
      history.push('/promocion/marca-blanca');
    }
  }, [dispatch, id_edit]);

  if (loading) return <Loading />;

  return (
    <Row className="main-white-brand container-fluid">
      <Col sm="12">
        <Tabs>
          <Tab title={<FormattedMessage id="global-configuration" />}>
            <Card className="mb-2">
              <Card.Header>
                <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> <FormattedMessage id="promo-setup-white-brand" /> {name}{' '}
              </Card.Header>
              <Card.Body>
                <Form id="form" className="form">
                  <Row>
                    <Col xl="6">
                      <Form.Group as={Row} className="mb-3 required" controlId="formAfno">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              <FormattedMessage id="herramientas-white-label-tracking-id-explanation" />
                            </Tooltip>
                          }>
                          <Form.Label column sm="3" className="font-weight-bold">
                            <span className="text">
                              <FormattedMessage id="global-campaign-id" />
                            </span>
                            <span className="help-icon float-right">
                              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                            </span>
                          </Form.Label>
                        </OverlayTrigger>

                        <Col sm="9">
                          <InputGroup className="">
                            <InputGroup.Text>{prefix_afno}</InputGroup.Text>
                            <Form.Control className="w-auto f-left" value={afno} placeholder="" name="afno" disabled />
                          </InputGroup>

                          {validator.message(formatMessage({ id: 'global-campaign-id' }), afno, 'max: 150|required')}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="formNotificationsEmail">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              <FormattedMessage id="herramientas-white-label-email-notification-explanation" />
                            </Tooltip>
                          }>
                          <Form.Label column sm="3" className="font-weight-bold">
                            <span className="text">
                              <FormattedMessage id="global-notification-email" />
                            </span>
                            <span className="help-icon float-right">
                              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                            </span>
                          </Form.Label>
                        </OverlayTrigger>
                        <Col sm="9">
                          <Form.Control
                            value={notifications_email}
                            name="notifications_email"
                            placeholder=""
                            onChange={(e) => setNotificationsEmail(e.target.value)}
                          />
                          {validator.message(
                            formatMessage({ id: 'global-notification-email' }),
                            notifications_email,
                            'max: 255|email|required'
                          )}
                        </Col>
                      </Form.Group>

                      {id_edit && logo && (
                        <Form.Group as={Row} className="mb-3" controlId="formLogo">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                <FormattedMessage id="herramientas-white-label-logo-explanation" />
                              </Tooltip>
                            }>
                            <Form.Label column sm="3" className="font-weight-bold">
                              <span className="text">Logo</span>
                              <span className="help-icon float-right">
                                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                              </span>
                            </Form.Label>
                          </OverlayTrigger>
                          <Col sm="9">
                            <img style={{ maxWidth: '128px' }} src={logo} className={'img-preview'} alt="" />
                          </Col>
                        </Form.Group>
                      )}

                      {id_edit && favicon && (
                        <Form.Group as={Row} className="mb-3" controlId="formFavicon">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                <FormattedMessage id="herramientas-white-label-favicon-explanation" />
                              </Tooltip>
                            }>
                            <Form.Label column sm="3" className="font-weight-bold">
                              <span className="text">Favicon</span>
                              <span className="help-icon float-right">
                                <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                              </span>
                            </Form.Label>
                          </OverlayTrigger>
                          <Col sm="9">
                            <img style={{ maxWidth: '128px' }} src={favicon} className={'img-preview'} alt="" />
                          </Col>
                        </Form.Group>
                      )}

                      <Form.Group as={Row} className="mb-3" controlId="formPrimaryColor">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              <FormattedMessage id="herramientas-white-label-primary-color-explanation" />
                            </Tooltip>
                          }>
                          <Form.Label column sm="3" className="font-weight-bold">
                            <span className="text">
                              <FormattedMessage id="global-primary-color" />
                            </span>
                            <span className="help-icon float-right">
                              <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                            </span>
                          </Form.Label>
                        </OverlayTrigger>
                        <Col sm="9">
                          <Form.Control
                            type="color"
                            name="primary_color"
                            value={primary_color}
                            title="Seleccione el color"
                            onChange={(e) => debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
                          />
                        </Col>
                      </Form.Group>

                      <Social
                        twitter={twitter}
                        saveTwitter={saveTwitter}
                        instagram={instagram}
                        saveInstagram={saveInstagram}
                        telegram={telegram}
                        saveTelegram={saveTelegram}
                        social_default_image={social_default_image}
                        saveSocialDefaultImage={saveSocialDefaultImage}
                        saveImage={saveImage}
                        validator={validator}
                        edit={true}
                      />
                      <Analitic gtm={gtm} ga={ga} setGTM={setGTM} setGA={setGA} />
                    </Col>
                    <Col xl="6">
                      <DomainsList domain={domain} setDomain={setDomain} edit={true} validator={validator} />
                      <Legal
                        domain_owner_name={domain_owner_name}
                        saveDomainName={saveDomainName}
                        domain_owner_email={domain_owner_email}
                        saveDomainEmail={saveDomainEmail}
                        validator={validator}
                      />
                    </Col>
                  </Row>
                  {/* <NewMetatagSection sitemode={mode} metatagsItems={metatagsItems} setMetatagsItems={setMetatagsItems} /> */}
                  <Preview logo={logo} color={primary_color} id_edit={id_edit} />
                  <Row>
                    <Col>
                      <CustomButton rounded className="w-100" onClick={updateBtn}>
                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                        {id_edit ? (
                          <span>
                            <FormattedMessage id="global-update" />
                          </span>
                        ) : (
                          <span>
                            <FormattedMessage id="administration-data-button" />
                          </span>
                        )}
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Tab>
          {id_edit && (
            <Tab title="SEO">
              <Seo id={id_edit} sitemode={mode} />
            </Tab>
          )}
        </Tabs>
      </Col>
    </Row>
  );
};

export default WhiteBrandForm;
