import originCountries from 'shared/json/originCountries.json';
import segments from 'shared/json/segments.json';
import _categories from 'shared/json/categories.json';

const sectionIds = {
  girls: 1,
  //boys: 3,
  trans: 2,
  couples: 4,
};

export const buildPathnames = ({ section, filter, langs }) => {
  const result = {};

  langs.forEach((lang) => {
    const buildedLang = lang === 'en' ? '' : lang;
    let url = `/${buildedLang}`;

    if (section === 'home') {
      result[lang] = url;
    } else if (['room', 'webcam', 'profile'].includes(section)) {
      url += `/${section}/[nick]`;
      result[lang] = url.replace(/\/+/g, '/');
    } else {
      result[lang] = buildSegmentPathname({ section, filter, lang });
    }
  });

  return result;
};

const buildSegmentPathname = ({ section, filter, lang }) => {
  const buildedLang = lang === 'en' ? '' : lang;
  let url = `/${buildedLang}`;

  let segmentId = sectionIds[section];
  let segment = segments.find((segment) => segment.id === segmentId && segment.slugs[lang]);

  if (section in sectionIds) {
    if (segment) url += `/${segment.slugs[lang]}`;
  }

  if (filter) {
    let country = originCountries.find((country) => country.country === filter);
    if (country) url += `/${country.slugsFemale[lang]}`;

    let category = _categories.find((category) => category.segments.includes(segmentId) && category.id.toString() === filter.toString());
    if (category) url += `/${category.slugs[lang]}`;
  }

  return url.replace(/\/+/g, '/');
};
